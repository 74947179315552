import React, {useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import BoxWrapper from '../../Wrapper/BoxWrapper';
import {LazyMotion, domAnimation, m} from 'framer-motion';
import {HiOutlineArrowLongRight} from '@react-icons/all-files/hi2/HiOutlineArrowLongRight';
import Button from '../Button/Button';

const ButtonIcon = () => <HiOutlineArrowLongRight className="h-6 w-6" />;

/**
 *
 * @param {Object} props - React props
 * @param {[Object]} props.items - WebDesignProcessItems list items
 * @param {number} props.durationEffect - duration move to another step
 * @param {string} props.className - Classes of this component
 *
 * @returns
 */
const InteractiveCircleMenu = ({
  className = '',
  items,
  durationEffect = 30000,
}) => {
  const [clickItemIdx, setClickItemIdx] = useState(1);

  // Each 30s, items automatic next
  useEffect(() => {
    const interval = setInterval(() => {
      setClickItemIdx((prev) => prev + 1);
      if (clickItemIdx > items?.length - 1) {
        setClickItemIdx(1);
      }
    }, durationEffect);
    return () => clearInterval(interval);
  }, [clickItemIdx, setClickItemIdx, items]);

  return (
    <BoxWrapper className={`${className} mt-6 items-center justify-center`}>
      <div className="relative h-[38rem] w-[38rem] rounded-full border-2 border-primary">
        {items.map((item) => (
          <div key={item.idx}>
            <div
              className={`absolute flex items-center gap-5 ${
                item.idx > 4 ? 'flex-row-reverse' : ''
              }`}
              style={{
                top: `${item.top}`,
                left: `${item.left}`,
                right: `${item.right}`,
              }}
              onClick={() => {
                setClickItemIdx(item.idx);
              }}
            >
              <div>
                <p
                  className={`${
                    item.idx === clickItemIdx
                      ? 'text-primary'
                      : 'text-[#2E3244]'
                  } cursor-pointer font-semibold`}
                >
                  {item.title}
                </p>
              </div>
              <LazyMotion features={domAnimation} strict>
                <m.div
                  className={`flex h-[5rem] w-[5rem] cursor-pointer items-center justify-center rounded-full hover:bg-primary hover:text-white ${
                    item.idx === clickItemIdx
                      ? 'bg-primary text-white'
                      : 'bg-white text-primary'
                  }`}
                  whileTap={{scale: 0.9}}
                  whileHover={{scale: 1.2}}
                  style={{boxShadow: '4px 4px 16px #ECF0FF'}}
                >
                  <p
                    className={`text-3xl font-black 
                `}
                  >
                    {item.idxContent}
                  </p>
                </m.div>
              </LazyMotion>
            </div>

            {item.idx === clickItemIdx ? (
              <>
                <div className="flex h-[35rem] w-full flex-col items-center justify-center gap-5 p-[7rem]">
                  <p className="text-7xl font-bold text-[#4767ec66]">
                    {item.idxContent}
                  </p>

                  <h3 className="text-2xl font-bold text-[#2E3244]">
                    {item.title}
                  </h3>

                  <p>{item.content}</p>
                  <Button
                    type="primary"
                    content="Tư vấn báo giá"
                    Icon={ButtonIcon}
                    className="w-fit gap-2 px-6 py-2 font-medium"
                    onClick={() => navigate('/lien-he')}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </BoxWrapper>
  );
};

export default InteractiveCircleMenu;
