import {LazyMotion, domAnimation, m} from 'framer-motion';
import React, {useEffect, useState} from 'react';
import BoxWrapper from '../Wrapper/BoxWrapper';
/**
 *
 * @param {Object} props - React props
 * @param {[Object]} props.items - WebDesignProcessItems list items
 * @param {number} props.durationEffect - duration move to another step
 * @param {string} props.className - Classes of this component
 *
 * @returns
 */
const InteractiveProcessLine = ({
  className = '',
  items,
  durationEffect = 30000,
}) => {
  const [clickItemIdx, setClickItemIdx] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setClickItemIdx((prev) => prev + 1);
      if (clickItemIdx > items?.length - 1) {
        setClickItemIdx(1);
      }
    }, durationEffect);
    return () => clearInterval(interval);
  }, [clickItemIdx, setClickItemIdx, items]);

  return (
    <BoxWrapper
      className={`${className} items-center justify-center pl-10 md:pl-12`}
    >
      <ol className="relative border-l border-gray-200">
        {items.map((item) => (
          <li
            className="mb-5 ml-10 cursor-pointer md:mb-7"
            onClick={() => setClickItemIdx(item.idx)}
            key={item.idx}
          >
            {/* For Circle icon */}
            <LazyMotion features={domAnimation} strict>
              <m.div
                className={`absolute -left-6 mt-1.5 flex h-12 w-12 items-center justify-center rounded-full hover:bg-primary hover:text-white lg:left-[-2rem]
              ${
                clickItemIdx === item.idx
                  ? 'bg-primary text-white'
                  : 'bg-white text-black'
              }
            `}
                style={{boxShadow: '4px 4px 16px #ECF0FF'}}
                whileTap={{scale: 0.9}}
                whileHover={{scale: 1.2}}
              >
                <p className="text-base font-bold">{item.idxContent}</p>
              </m.div>
            </LazyMotion>

            {/* For title */}
            <h3
              className={`pt-4 text-lg font-semibold
              ${clickItemIdx === item.idx ? 'text-primary' : 'text-black'}
            `}
            >
              {item.title}
            </h3>

            {/* For content */}
            <p className="mb-4 text-base font-normal text-gray-500 ">
              {item.content}
            </p>
          </li>
        ))}
      </ol>
    </BoxWrapper>
  );
};

export default InteractiveProcessLine;
