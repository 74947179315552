import {HiCheckBadge} from '@react-icons/all-files/hi2/HiCheckBadge';
import {HiOutlineArrowLongRight} from '@react-icons/all-files/hi2/HiOutlineArrowLongRight';
import {graphql, navigate} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import Seo from '../../components/Seo/Seo';
import BoxWrapper from '../../components/Wrapper/BoxWrapper';
import CardWrapper from '../../components/Wrapper/CardWrapper';
import Button from '../../components/_baseComponents/Button/Button';
import SectionHeading from '../../components/_baseComponents/SectionHeading/SectionHeading';
import InteractiveCircleMenu from '../../components/_baseComponents/InteractiveCircleMenu/InteractiveCircleMenu';
import InteractiveProcessLine from '../../components/InteractiveProcessLine/InteractiveProcessLine';
import {SeoStepsProcessItems} from '../../staticData/SeoStepsProcessItems';
const SEOServicesPage = () => {
  return (
    <div className="flex flex-col gap-12 pt-[80px] md:gap-16 lg:gap-20 lg:pt-20">
      <BoxWrapper
        className={`mt-6 flex-col-reverse items-center justify-between gap-6 md:mt-8 md:flex-row md:gap-6 lg:mt-12 lg:w-full`}
      >
        <div className="flex w-full flex-col gap-6">
          <div className="flex flex-col gap-2">
            <div className="relative w-fit border-2 border-dashed border-primary px-5 py-4 text-3xl font-bolder text-primary md:text-4xl lg:text-5xl">
              {/* top left point */}
              <div className="absolute -left-1 -top-1 h-2 w-2 bg-primary"></div>

              {/* top right point */}
              <div className="absolute -right-1 -top-1 h-2 w-2 bg-primary"></div>

              <div>Dịch vụ SEO</div>

              {/* bottom left point */}
              <div className="absolute -bottom-1 -left-1 h-2 w-2 bg-primary"></div>

              {/* bottom right point */}
              <div className="absolute -bottom-1 -right-1 h-2 w-2 bg-primary"></div>
            </div>
            <div className="text-3xl font-bolder text-gray-900 md:text-4xl lg:text-5xl">
              tổng thể
            </div>
          </div>
          <div className="flex flex-col gap-6 lg:gap-16">
            <div className="flex flex-col items-start gap-4 md:text-sm lg:w-full lg:text-base">
              <div className="flex flex-row gap-2">
                <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                <div>Website lên top hàng nghìn từ khóa</div>
              </div>
              <div className="flex flex-row gap-2">
                <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                <div>Tối ưu nội dung tăng chuyển đổi khách hàng</div>
              </div>
              <div className="flex flex-row gap-2">
                <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                <div>Tư vấn chiến lược SEO Marketing theo mục tiêu</div>
              </div>
              <div className="flex flex-row gap-2">
                <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                <div>Báo cáo nghiệm thu theo tháng</div>
              </div>
              <div className="flex flex-row gap-2">
                <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                <div>Báo cáo nghiệm thu theo tháng</div>
              </div>
            </div>
            <div>
              <Button
                type="primary"
                content="Tư vấn miễn phí"
                Icon={ButtonIcon}
                className="flex justify-center gap-2 rounded px-6 py-2 font-medium md:flex-none lg:flex-none"
                onClick={() => {
                  navigate('/lien-he');
                }}
              />
            </div>
          </div>
        </div>
        <div className="relative w-fit self-center md:flex-none lg:self-start">
          {/* Bg effect */}
          <div className="  absolute -left-10 -top-10 before:-z-10 before:block before:h-[200px] before:w-[200px] before:rounded-full before:bg-blue-600/10 before:blur-[2rem] before:will-change-[filter] lg:-left-40 lg:-top-20  before:lg:h-[435px] before:lg:w-[435px] before:lg:blur-[4rem]" />
          {/* Bg effect */}
          <div className=" -lg:right-20 absolute -right-10 -top-10 before:-z-10 before:block before:h-[200px] before:w-[200px] before:rounded-full before:bg-red-600/10 before:blur-[2rem] before:will-change-[filter] lg:-top-20  before:lg:h-[435px] before:lg:w-[435px] before:lg:blur-[4rem]" />
          {/* Bg effect */}
          <div className="absolute -bottom-10 -left-0 before:-z-10 before:block before:h-[200px]  before:w-[200px] before:rounded-full before:bg-cyan-600/10 before:blur-[2rem] before:will-change-[filter] lg:-bottom-20 lg:-left-40  before:lg:h-[435px] before:lg:w-[435px] before:lg:blur-[4rem]" />
          {/* Bg effect */}
          <div className=" absolute -bottom-10 -right-10 before:-z-10 before:block before:h-[200px] before:w-[200px] before:rounded-full before:bg-green-600/10 before:blur-[2rem] before:will-change-[filter] lg:-bottom-52 lg:-right-20  before:lg:h-[435px] before:lg:w-[435px] before:lg:blur-[4rem]" />

          <div className="absolute -left-4 top-4 flex w-fit flex-col gap-1 rounded-md bg-white px-4 pb-3 pt-1 drop-shadow-md lg:-left-12 lg:top-7 lg:gap-2 lg:rounded-xl">
            <div className="font-bolder text-teal-500 lg:text-xl ">User</div>
            <div className="flex flex-row gap-1 lg:gap-2">
              <StaticImage
                src="../../assets/services/SEO-Services/user-1.png"
                alt="Customer avatar"
                width={236}
                height={236}
                className="h-6 w-6 rounded-full border border-black/10 lg:h-12 lg:w-12"
                objectFit="contain"
                imgClassName="rounded-full"
              />
              <StaticImage
                src="../../assets/services/SEO-Services/user-2.png"
                alt="Customer avatar"
                width={236}
                height={236}
                className="h-6 w-6 rounded-full border border-black/10 lg:h-12 lg:w-12"
                objectFit="contain"
                imgClassName="rounded-full"
              />
              <StaticImage
                src="../../assets/services/SEO-Services/user-3.png"
                alt="Customer avatar"
                width={236}
                height={236}
                className="h-6 w-6 rounded-full border border-black/10 lg:h-12 lg:w-12"
                objectFit="contain"
                imgClassName="rounded-full"
              />
              <div className="relative">
                <div className="absolute z-[3] flex h-6 w-6 items-center justify-center rounded-full border border-black/10 bg-black/30 text-[.5rem] font-bolder  text-white lg:h-12 lg:w-12 lg:text-sm">
                  +25K
                </div>
                <StaticImage
                  src="../../assets/services/SEO-Services/user-4.png"
                  alt="Customer avatar"
                  width={236}
                  height={236}
                  className="h-6 w-6 rounded-full border border-black/10 lg:h-12 lg:w-12"
                  objectFit="contain"
                  imgClassName="rounded-full"
                />
              </div>
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../assets/services/SEO-Services/rocket-fly-up.png"
              alt="Customer avatar"
              width={800}
              className="max-w-[320px] md:max-w-[380px] lg:max-w-[500px]"
            />
          </div>

          <div className="absolute -right-4 bottom-10 z-[2] flex w-fit flex-row items-end gap-1 rounded-md bg-white px-2 py-3 drop-shadow-md md:right-0 lg:bottom-16 lg:rounded-xl lg:px-3 lg:py-4">
            <span className="text-xl font-extrabold leading-none text-primary lg:text-3xl">
              80k
            </span>
            <span className="text-lg font-bold leading-none text-gray-500 lg:text-2xl">
              Reviews
            </span>
          </div>
        </div>
      </BoxWrapper>

      {/* Metric Section */}
      <BoxWrapper className={`items-center justify-center lg:w-full`}>
        <div className="z-10 flex w-full flex-col items-center justify-center gap-14 rounded-lg bg-white px-8 py-10 text-center shadow-card-layout shadow-neutral-500/10 md:flex-row md:flex-wrap lg:w-full lg:flex-row lg:flex-nowrap lg:justify-between">
          <div className="flex flex-col items-center justify-center md:w-[42%] lg:w-auto">
            <div className="text-4xl font-bolder text-primary">9+</div>
            <div className="text-xl text-gray-400">
              Khách hàng SEO hàng tháng
            </div>
          </div>
          <div className="flex flex-col items-center justify-center md:w-[42%] lg:w-auto">
            <div className="text-4xl font-bolder text-primary">100+</div>
            <div className="text-xl text-gray-400">
              Top Google với hàng nghìn từ khoá
            </div>
          </div>
          <div className="flex flex-col items-center justify-center md:w-[42%] lg:w-auto">
            <div className="text-4xl font-bolder text-primary">9.000+</div>
            <div className="text-xl text-gray-400">
              Tổng số lần hiển thị trên Google
            </div>
          </div>
        </div>
      </BoxWrapper>

      <BoxWrapper className={``}>
        <div className="flex flex-col justify-center gap-2 overflow-hidden rounded-xl bg-white/70 p-4 lg:gap-4 lg:rounded-[2.5rem] lg:px-8 lg:py-14 xl:gap-8">
          <div>
            {/* Bg effect */}
            <div className="relative before:absolute before:-left-[10rem] before:-top-[20rem] before:-z-10 before:block before:h-[800px] before:w-[800px] before:rounded-full before:bg-cyan-500/60 before:blur-[8rem] before:will-change-[filter]" />
            <SectionHeading
              content="Khái quát dịch vụ SEO"
              className="w-full items-center lg:ml-8 lg:items-start"
              contentClassName="flex flex-row text-2xl md:text-3xl lg:text-4xl text-gray-900"
            />
          </div>
          <div className="flex flex-col justify-center gap-4 text-gray-900 md:gap-6 lg:flex-row lg:gap-8">
            {/* Bg effect */}
            <div className="relative before:absolute before:-bottom-[25rem] before:-left-[10vw] before:-z-10 before:block before:h-[800px] before:w-[800px] before:rounded-full before:bg-violet-500/80 before:blur-[9rem]" />

            <CardWrapper
              className="flex h-fit flex-col gap-2 rounded-lg bg-white/40 p-5 lg:w-[30%] lg:rounded-xl"
              isShadow={false}
            >
              <StaticImage
                src="../../assets/services/webapp/check-money.png"
                alt="checkphone-man"
                width={128}
                height={128}
                className="h-16 w-16"
                objectFit="cover"
              />
              <div className="flex flex-col gap-3">
                <h3 className="text-lg font-bolder">Dịch vụ SEO là gì?</h3>
                <p className="text-gray-500">
                  Dịch vụ SEO là tổng thể nhiều dịch vụ được cung cấp bởi công
                  ty SEO chuyên nghiệp hoặc các chuyên gia trong lĩnh vực SEO.
                  Họ sẽ giúp quảng bá thương hiệu của công ty bạn trên Google
                  một cách tối ưu nhất. Bằng cách thông qua công cụ tìm kiếm
                  (Google Search Engine). Dịch vụ SEO Website cũng là một phương
                  pháp tối ưu kết quả tìm kiếm sản phẩm, dịch vụ và ngành liên
                  quan của công ty bạn.
                </p>
              </div>
            </CardWrapper>
            <CardWrapper
              className="flex h-fit flex-col gap-2 rounded-lg bg-white/40 p-5 lg:w-[30%] lg:rounded-xl "
              isShadow={false}
            >
              <StaticImage
                src="../../assets/services/webapp/check-money.png"
                alt="checkphone-man"
                width={128}
                height={128}
                className="h-16 w-16"
                objectFit="cover"
              />
              <div className="flex flex-col gap-3">
                <h3 className="text-lg font-bolder">Dịch vụ SEO từ khóa</h3>
                <p className="text-gray-500">
                  Dịch vụ SEO từ khoá là một loại hình SEO phổ biến nhắm mục
                  tiêu cho các từ khóa cụ thể để tối ưu. Phương pháp này thường
                  được dùng cho những chiến lược SEO từ khóa chính để xếp hạng
                  cao trên Google. Bao gồm các top cao như top 10, top 5, top 3
                  hoặc thậm chí là cạnh tranh top 1. Dịch vụ SEO từ khóa uy tín
                  thường ít tốn chi phí hơn so với loại khác do chỉ cần tập
                  trung vào ít mảng.
                </p>
              </div>
            </CardWrapper>
            <CardWrapper
              className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 lg:w-[30%] lg:rounded-xl"
              isShadow={false}
            >
              <StaticImage
                src="../../assets/services/webapp/check-pen.png"
                alt="checkphone-man"
                width={128}
                height={128}
                className="h-16 w-16"
                objectFit="cover"
              />
              <div className="flex flex-col gap-3">
                <h3 className="text-lg font-bolder">
                  Dịch vụ SEO Website tổng thể
                </h3>
                <p className="text-gray-500">
                  Dịch vụ SEO tổng thể Website chính là dịch vụ mà hầu hết các
                  công ty SEO thuê ngoài tại Việt Nam cung cấp. Thường dịch vụ
                  SEO Website tổng thể sẽ phải có một kế hoạch chiến lược dài
                  hạn. Thường sẽ kéo dài khoảng 6 đến 12 tháng. Chiến lược này
                  sẽ tác động đến toàn bộ webpage nằm trong website. Điều này
                  giúp hiển thị tối đa số lượng từ khóa khi khách hàng tìm kiếm
                  thông tin liên quan. Dịch vụ SEO Website tổng thể tuy tốn chi
                  phí cao nhưng nó mang lại cho doanh nghiệp rất nhiều lợi ích.
                </p>
              </div>
            </CardWrapper>
            {/* Bg effect */}
            <div className="relative before:absolute before:-bottom-[10rem] before:-left-[20vw] before:-z-10 before:block before:h-[800px] before:w-[800px] before:rounded-full before:bg-purple-400/80 before:blur-[9rem]" />
          </div>
        </div>
      </BoxWrapper>

      <BoxWrapper className="flex-col gap-14">
        <SectionHeading
          content="Tại sao doanh nghiệp của bạn cần được thực hiện SEO?"
          className="w-full items-center justify-center md:items-center"
          contentClassName="flex flex-row text-center text-2xl text-gray-900 md:text-3xl lg:text-4xl lg:text-left"
        />
        <div className="flex flex-col items-center gap-0 lg:flex-row">
          <div className="relative w-fit flex-none lg:w-1/2">
            {/* Bg effect */}
            <div className="  -left-45 absolute -top-12 before:-z-10 before:block before:h-[200px] before:w-[200px] before:rounded-full before:bg-blue-600/10 before:blur-[2rem] before:will-change-[filter] lg:-top-20 before:lg:h-[360px] before:lg:w-[360px] before:lg:blur-[4rem]" />
            {/* Bg effect */}
            <div
              className=" absolute -right-2 -top-5
               before:-z-10 before:block before:h-[200px] before:w-[200px] before:rounded-full before:bg-red-600/10 before:blur-[2rem] before:will-change-[filter] before:lg:h-[360px] before:lg:w-[360px] before:lg:blur-[4rem]"
            />
            {/* Bg effect */}
            <div className="absolute -bottom-12 -left-10 before:-z-10 before:block before:h-[200px] before:w-[200px] before:rounded-full before:bg-cyan-600/10 before:blur-[2rem] before:will-change-[filter] lg:-bottom-20 lg:-left-40  before:lg:h-[360px] before:lg:w-[360px] before:lg:blur-[4rem]" />
            {/* Bg effect */}
            <div className=" absolute -bottom-20 right-10 before:-z-10 before:block before:h-[200px] before:w-[200px] before:rounded-full before:bg-yellow-400/10 before:blur-[2rem] before:will-change-[filter] lg:right-20  before:lg:h-[360px] before:lg:w-[360px] before:lg:blur-[4rem]" />
            <div className="">
              <StaticImage
                src="../../assets/services/SEO-Services/blue-earth.png"
                alt="Customer avatar"
                width={800}
                className="w-[300px] md:w-[380px] lg:w-[450px]"
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-between gap-4 lg:w-1/2">
            <CardWrapper
              className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 md:w-[47%] lg:w-[48%] lg:rounded-xl"
              isShadow={false}
            >
              <StaticImage
                src="../../assets/services/SEO-Services/mission-vision-image-1.png"
                alt="checkphone-man"
                width={128}
                height={128}
                className="h-16 w-16"
                objectFit="cover"
              />
              <div className="flex flex-col gap-3">
                <h3 className="text-lg font-bolder">
                  Trọn gói tất cả trong một
                </h3>
                <p className="text-gray-500">
                  Dịch vụ SEO Overall tại KDIGI ngoài công việc SEO Onpage /
                  Offpage, chúng tôi còn quản trị xây dựng nội dung website miễn
                  phí, phát triển các kênh marketing online vệ tinh. Xây dựng uy
                  tín cho tác giả, chuyên gia, chủ doanh nghiệp, thương hiệu ...
                  của quý khách một cách toàn diện.
                </p>
              </div>
            </CardWrapper>
            <CardWrapper
              className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 md:w-[47%] lg:w-[48%] lg:rounded-xl"
              isShadow={false}
            >
              <StaticImage
                src="../../assets/services/SEO-Services/mission-vision-image-2.png"
                alt="checkphone-man"
                width={128}
                height={128}
                className="h-16 w-16"
                objectFit="cover"
              />
              <div className="flex flex-col gap-3">
                <h3 className="text-lg font-bolder">Tiết kiệm chi phí</h3>
                <p className="text-gray-500">
                  Doanh nghiệp bạn đang có đội ngũ SEO, nhưng hoạt động không
                  hiệu quả. Hoặc tiêu tốn hàng chục đến hàng trăm triệu hàng
                  tháng Google Ads và những kênh digital marketing khác nhưng
                  không hiệu quả.Chỉ từ 20 triệu/tháng với dịch vụ SEO giá rẻ
                  của KDIGI, sẽ giải quyết những nỗi lo về chi phí của doanh
                  nghiệp bạn!
                </p>
              </div>
            </CardWrapper>
            <CardWrapper
              className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 md:w-[47%] lg:w-[48%] lg:rounded-xl"
              isShadow={false}
            >
              <StaticImage
                src="../../assets/services/SEO-Services/mission-vision-image-3.png"
                alt="checkphone-man"
                width={128}
                height={128}
                className="h-16 w-16"
                objectFit="cover"
              />
              <div className="flex flex-col gap-3">
                <h3 className="text-lg font-bolder">
                  Tự tin mở rộng kinh doanh
                </h3>
                <p className="text-gray-500">
                  Ngoài lợi ích phát triển nhận diện thương hiệu. Sản phẩm và
                  dịch vụ của quý doanh nghiệp sẽ xuất hiện đúng vào nhu cầu tìm
                  kiếm của khách hàng, từ đó giúp người dùng (khách hàng) tin
                  tưởng & yên tâm hơn, tăng tỷ lệ chuyển đổi khách hàng.
                </p>
              </div>
            </CardWrapper>
            <CardWrapper
              className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 md:w-[47%] lg:w-[48%] lg:rounded-xl"
              isShadow={false}
            >
              <StaticImage
                src="../../assets/services/SEO-Services/mission-vision-image-4.png"
                alt="checkphone-man"
                width={128}
                height={128}
                className="h-16 w-16"
                objectFit="cover"
              />
              <div className="flex flex-col gap-3">
                <h3 className="text-lg font-bolder">Phát triển thương hiệu</h3>
                <p className="text-gray-500">
                  Sẵn sàng phủ TOP ngành nghề trên Google với chiến dịch SEO
                  tổng thể, từ đó xuất hiện kết quả mà người dùng truy vấn tìm
                  kiếm Google. Thương hiệu của doanh nghiệp sẽ được phủ sóng
                  toàn diện trên môi trường internet.
                </p>
              </div>
            </CardWrapper>
          </div>
          {/* Bg effect */}
          <div className="relative before:absolute before:-top-[75rem] before:left-[5rem] before:-z-10 before:block  before:h-[800px] before:w-[800px] before:rounded-full before:bg-cyan-500/10 before:blur-[8rem] before:will-change-[filter] before:md:-top-[30rem] before:md:left-[7rem] before:lg:-left-[30rem] before:lg:-top-[15rem]" />

          {/* Bg effect for mobile and tablet */}
          <div className="relative before:absolute before:-top-[45rem] before:right-[6rem] before:-z-10 before:block before:h-[800px] before:w-[800px] before:rounded-full before:bg-rose-500/10 before:blur-[8rem] before:will-change-[filter] lg:hidden before:lg:-right-[30rem] before:lg:-top-[15rem]" />
        </div>
      </BoxWrapper>

      <BoxWrapper className={'mt-[7rem]'}>
        <SectionHeading
          content="Quy trình thiết kế Website"
          className="w-full items-center text-[3rem]"
        />
      </BoxWrapper>

      {/* Interactive Circle Reason */}
      <>
        <InteractiveCircleMenu
          items={SeoStepsProcessItems}
          className="hidden lg:flex"
        />
      </>
      <div>
        <InteractiveProcessLine
          items={SeoStepsProcessItems}
          className="lg:hidden"
        />

        {/* For Button */}
        <div className="mt-8 flex justify-center lg:hidden">
          <Button
            type="primary"
            content="Tư vấn báo giá"
            Icon={ButtonIcon}
            className="w-fit gap-2 px-6 py-2 font-medium"
            onClick={() => navigate('/lien-he')}
          />
        </div>
      </div>
    </div>
  );
};

const ButtonIcon = () => <HiOutlineArrowLongRight className="h-6 w-6" />;

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({data: {wpPage}}) => {
  return (
    <Seo
      yoastSeo={wpPage?.seo}
      title={wpPage?.title}
      uri={wpPage?.uri}
      description={wpPage?.content && wpPage.content.substring(150)}
    />
  );
};

export default SEOServicesPage;

export const pageQuery = graphql`
  query {
    wpPage(uri: {eq: "/dich-vu/dich-vu-seo"}) {
      id
      uri
      title
      content
      seo {
        fullHead
        canonical
      }
    }
  }
`;
