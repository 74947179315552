export const SeoStepsProcessItems = [
  // Left side
  {
    idx: 1,
    idxContent: '01',
    title: 'Nghiên cứu từ khóa của dịch vụ SEO',
    content:
      'Việc đầu tiên để thực hiện SEO là bạn phải nghiên cứu từ khóa chính có liên quan đến lĩnh vực mà bạn muốn hướng đến. Có rất nhiều cách để triển khai kế hoạch nghiên cứu nhờ vào các công cụ khác nhau. Thông thường, những công cụ có sự đa dạng và chi số nghiên cứu chi tiết sẽ cần trả phí. Bạn có thể tham khảo các công cụ tối ưu như Google Keyword Planner, Keyword Tool, Ahrefs, Semrush,vv.',
    top: '1rem',
    left: '-13.2rem',
    right: '0',
    bottom: '0',
  },

  {
    idx: 2,
    idxContent: '02',
    title: 'Thu hẹp danh sách từ khóa',
    content:
      'Việc đầu tiên để thực hiện SEO là bạn phải nghiên cứu từ khóa chính có liên quan đến lĩnh vực mà bạn muốn hướng đến. Có rất nhiều cách để triển khai kế hoạch nghiên cứu nhờ vào các công cụ khác nhau. Thông thường, những công cụ có sự đa dạng và chi số nghiên cứu chi tiết sẽ cần trả phí. Bạn có thể tham khảo các công cụ tối ưu như Google Keyword Planner, Keyword Tool, Ahrefs, Semrush,vv.',
    top: '10rem',
    left: '-15.8rem',
    right: '0',
    bottom: '0',
  },
  {
    idx: 3,
    idxContent: '03',
    title: 'Xem xét mục đích tìm kiếm cho từ khóa',
    content:
      'Việc đầu tiên để thực hiện SEO là bạn phải nghiên cứu từ khóa chính có liên quan đến lĩnh vực mà bạn muốn hướng đến. Có rất nhiều cách để triển khai kế hoạch nghiên cứu nhờ vào các công cụ khác nhau. Thông thường, những công cụ có sự đa dạng và chi số nghiên cứu chi tiết sẽ cần trả phí. Bạn có thể tham khảo các công cụ tối ưu như Google Keyword Planner, Keyword Tool, Ahrefs, Semrush,vv.',
    top: '20rem',
    left: '-22.2rem',
    right: '0',
    bottom: '0',
  },
  {
    idx: 4,
    idxContent: '04',
    title: 'Nghiên cứu từ khóa của đối thủ',
    content:
      'Việc đầu tiên để thực hiện SEO là bạn phải nghiên cứu từ khóa chính có liên quan đến lĩnh vực mà bạn muốn hướng đến. Có rất nhiều cách để triển khai kế hoạch nghiên cứu nhờ vào các công cụ khác nhau. Thông thường, những công cụ có sự đa dạng và chi số nghiên cứu chi tiết sẽ cần trả phí. Bạn có thể tham khảo các công cụ tối ưu như Google Keyword Planner, Keyword Tool, Ahrefs, Semrush,vv.',
    top: '30rem',
    left: '-12rem',
    right: '0',
    bottom: '0',
  },

  // Right side
  {
    idx: 5,
    idxContent: '05',
    title: 'Xây dựng kế hoạch và tiến hành viết nội dung',
    content:
      'Việc đầu tiên để thực hiện SEO là bạn phải nghiên cứu từ khóa chính có liên quan đến lĩnh vực mà bạn muốn hướng đến. Có rất nhiều cách để triển khai kế hoạch nghiên cứu nhờ vào các công cụ khác nhau. Thông thường, những công cụ có sự đa dạng và chi số nghiên cứu chi tiết sẽ cần trả phí. Bạn có thể tham khảo các công cụ tối ưu như Google Keyword Planner, Keyword Tool, Ahrefs, Semrush,vv.',
    top: '1rem',
    left: '0',
    right: '-17.5rem',
    bottom: '0',
  },
  {
    idx: 6,
    idxContent: '06',
    title: 'Sử dụng chiến lược SEO Onpage hợp lý',
    content:
      'Việc đầu tiên để thực hiện SEO là bạn phải nghiên cứu từ khóa chính có liên quan đến lĩnh vực mà bạn muốn hướng đến. Có rất nhiều cách để triển khai kế hoạch nghiên cứu nhờ vào các công cụ khác nhau. Thông thường, những công cụ có sự đa dạng và chi số nghiên cứu chi tiết sẽ cần trả phí. Bạn có thể tham khảo các công cụ tối ưu như Google Keyword Planner, Keyword Tool, Ahrefs, Semrush,vv.',
    top: '10rem',
    left: '0',
    right: '-21.5rem',
    bottom: '0',
  },
  {
    idx: 7,
    idxContent: '07',
    title: 'Phát triển website vững chắc',
    content:
      'Việc đầu tiên để thực hiện SEO là bạn phải nghiên cứu từ khóa chính có liên quan đến lĩnh vực mà bạn muốn hướng đến. Có rất nhiều cách để triển khai kế hoạch nghiên cứu nhờ vào các công cụ khác nhau. Thông thường, những công cụ có sự đa dạng và chi số nghiên cứu chi tiết sẽ cần trả phí. Bạn có thể tham khảo các công cụ tối ưu như Google Keyword Planner, Keyword Tool, Ahrefs, Semrush,vv.',
    top: '20rem',
    left: '0',
    right: '-17.5rem',
    bottom: '0',
  },
  {
    idx: 8,
    idxContent: '08',
    title: 'Tìm các backlink chất lượng cho nội dung',
    content:
      'Việc đầu tiên để thực hiện SEO là bạn phải nghiên cứu từ khóa chính có liên quan đến lĩnh vực mà bạn muốn hướng đến. Có rất nhiều cách để triển khai kế hoạch nghiên cứu nhờ vào các công cụ khác nhau. Thông thường, những công cụ có sự đa dạng và chi số nghiên cứu chi tiết sẽ cần trả phí. Bạn có thể tham khảo các công cụ tối ưu như Google Keyword Planner, Keyword Tool, Ahrefs, Semrush,vv.',
    top: '30rem',
    left: '0',
    right: '-17.5rem',
    bottom: '0',
  },
];
